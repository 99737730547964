
import map from "../palestinemap.gif";
import React from "react";
import {Link} from "react-router-dom";

function NavigationVBar(){
    return(
        <nav-pages>
            <div className="portfolio-title">
                Nervous Turtle's Portfolio
            </div>
            <Link to="/">
                <button>
                    Back
                </button>
            </Link>
            <div className="portfolio-text">
                Hello! I am a front-end developer whose work revolves around accessibility, inclusion, and
                intention.
                I believe in projects that narrate a story, whatever it may be.
            </div>
            <img src={map} width="100%" alt="This will display an animated GIF map"/>
        </nav-pages>
    )
}

export default NavigationVBar;