import React from 'react';
import NavigationVBar from "../Components/NavigationVBar";
import screen1 from "./iterative/1_Screen_1.png";
import screen2 from "./iterative/1_Screen_2.png";
import screen3 from "./iterative/1_Screen_3.png"
import sketch1 from "./iterative/sketch1.jpg";
import sketch2 from "./iterative/sketch2.jpg";
import sketch3 from "./iterative/sketch3.jpg";
import sketch4 from "./iterative/sketch4.jpg";
import sketch5 from "./iterative/sketch5.jpg";
import sketch6 from "./iterative/sketch6.jpg";
import sketch7 from "./iterative/sketch7.jpeg";
import sketch8 from "./iterative/sketch8.jpeg";
import sketch9 from "./iterative/sketch9.jpeg";
import login from "./iterative/login.png";
import landing from "./iterative/landing.png";
import wire1 from "./iterative/wireframe_2.png";
import wire2 from "./iterative/wireframe_2.png";
import wire3 from "./iterative/wireframe_3.png";
import breakpic from "./iterative/break.png";
import friends1 from "./iterative/lifeat-friends1.png";
import friends2 from "./iterative/lifeat-friends2.png";
import minfriend1 from "./iterative/lifeat-friends-minimized1.png";
import minfriend2 from "./iterative/lifeat-friends-minimized2.png";
import oghifi8 from "./iterative/og_hifi_8.png";
import oghifi9 from "./iterative/og_hifi_9.png";
import oghifi10 from "./iterative/og_hifi_10.png";
import oghifi11 from "./iterative/og_hifi_11.png";
import oghifi12 from "./iterative/og_hifi_12.png";
import oghifi13 from "./iterative/og_hifi_13.png";
import oghifi7 from "./iterative/og_hifi_7.png";
import oghifi1 from "./iterative/og_hifi_1.png";
import oghifi6 from "./iterative/og_hifi_6.png";
import oghifi3 from "./iterative/og_hifi_3.png";
import oghifi4 from "./iterative/og_hifi_4.png";
import oghifi5 from "./iterative/og_hifi_5.png";
import example1 from "./iterative/example 1.png";
import example2 from "./iterative/example 2.png";
import example3 from "./iterative/example 3.png";


function iterative() {
    return(
        <personasbody>
            <div> </div>
            <NavigationVBar/>

            <div class="segment-blue">
                <div class="page-title">
                    LifeAt: Iterative Design
                </div>

                <div class="page-subtitle">How can we use the iterative design process to develop an interactive interface for an emerging startup?
                    Explore our journey as we go through the entire process of mocking up a solution to a startup's
                    concept, including sketches, prototypes, and user testing. Content is taken from a group project handout.
                </div>
            </div>

            <div className="segment-pink">
                <div className="segment-title">
                    Studying the Startup
                </div>

                <div class="body-text">We chose the startup <strong>LifeAt</strong>, which aims to serve as a "virtual getaway while you work". LifeAt's goal is to make doing work just a bit more relaxing and fun by
                    providing users with relaxing video backgrounds like a scenic beach, lush forest, or bustling cafe to look at while they work. More information about LifeAt can be found on their <a href="https://www.ycombinator.com/companies/lifeat" target="_blank">Y Combinator page</a>.</div>
                <div className="body-text">{'\n'}</div>
                <div class="body-text">
                        LifeAt's concept allows for a very broad user base: essentially any person who has a screen open while they work. However, we believe that
                        the concept would be most popular with individuals who work with larger screens or multiple screens, as they could have an entire screen devoted to LifeAt, and thus be
                        more easily immersed in their chosen video and audio. Based on this assumption, as well as the fact that the entire concept relies on having a large enough screen
                        to be able to easily see a selected background, we decided to design a desktop interface.
                </div>
            </div>

            <div className="segment-purple">
                <div className="segment-title">
                        Initial Sketches
                </div>
                <div className="page-subtitle">
                    Hover to Zoom In!
                </div>

                <div class="body-text">We began our journey to recreate <strong>LifeAt</strong> by sketching basic ideas individually in order to explore a variety of design choices.</div>
                <div className="cards-container">
                    <img className="card-img" src={screen1} />
                    <img className="card-img" src={screen2}/>
                    <img className="card-img" src={screen3}/>
                </div>
                <div className="cards-container">
                    <img className="card-img" src={sketch1} />
                    <img className="card-img" src={sketch2} />
                    <img className="card-img" src={sketch3} />
                </div>
                <div className="cards-container">
                    <img className="card-img" src={sketch4} />
                    <img className="card-img" src={sketch5} />
                    <img className="card-img" src={sketch6} />
                </div>
                <div className="cards-container">
                    <img className="card-img" src={sketch7} />
                    <img className="card-img" src={sketch8} />
                    <img className="card-img" src={sketch9} />
                </div>

                <div class="body-text"> {'\n'} </div>

                <div class="page-subtitle"> Key Ideas Explored in Sketches</div>

                <div className="body-text">
                    <li>A <strong>landing page</strong> that clearly displays (and links to) each of the key functionalities of the site</li>
                    <li>A <strong>pomodoro timer</strong> to allow users to have some sort of focus timer integration</li>
                    <li>Screens including a <strong>lo-fi mode</strong>, a <strong>slideshow page</strong>, and a <strong>white noise page</strong></li>
                    <li>A <strong>horizontal navigation bar</strong> with <strong>light/dark mode</strong></li>
                    <li>Different ways to select audio, and ways to integrate <strong>audio</strong> and <strong>video selection</strong></li>
                    <li><strong>"Take a Break"</strong> option, including a timer</li> and <strong>guided meditation</strong> options
                    <li>Social capabilities, including the option to <strong>schedule a break with a friend</strong></li>
                    <li>A <strong>minimized</strong> version, which could function as a side bar while you are doing other work on the screen</li>
                </div>

            </div>

            <div className="segment-yellow">
                <div className="segment-title">
                    Low Fidelity Wireframes
                </div>
                <div className="page-subtitle">
                    Hover to Zoom In!
                </div>

                <div class="body-text">
                    After creating a number of sketches, we discussed whether our sketches were effective at solving LifeAt's goals. We identified which
                    features addressed these goals in the most direct and creative manner, and created a set of low-fidelity wireframes based on these ideas.
                </div>

                <div class="body-text">
                    Key features of our wireframes include:
                </div>
                <div className="body-text">
                    <li>A landing page to clearly display the different functionalities of the site</li>
                    <li>A page for video and audio selection</li>
                    <li>A break page with guided meditation videos</li>
                    <li>A calendar page that allows users to schedule breaks with friends</li>
                    <li>A minimized version of the friends page</li>
                </div>

                <div className="cards-container">
                    <img className="card-img" src={login}/>
                    <img className="card-img" src={landing}/>
                </div>
                <div className="cards-container">
                    <img className="card-img" src={wire1}/>
                    <img className="card-img" src={wire2}/>
                    <img className="card-img" src={wire3}/>
                </div>
                <div className="cards-container">
                    <img className="card-img" src={breakpic}/>
                    <img className="card-img" src={friends1}/>
                    <img className="card-img" src={friends2}/>
                </div>
                <div className="cards-container" id="fix2">
                    <img className="card-img" id="small" src={minfriend1}/>
                    <img className="card-img" id="small" src={minfriend2}/>
                </div>

            </div>

            <div className="segment-blue">
                <div className="segment-title">
                    Initial Prototype
                </div>

                <div class="body-text">After critically evaluating our low-fidelity wireframes, we used Figma to refine and expand on them in the form of an interactive high-fidelity prototype. <a href="https://www.figma.com/proto/tSUc659V4fQFwxksMbOoTC/Lo-Fi-Iterative-Design?node-id=53%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=53%3A2&hide-ui=1" target="_blank">Explore our prototype and features here</a>!
                </div>


                <div className="cards-container">
                    <img className="card-img" id="proto" src={oghifi8}/>
                    <img className="card-img" id="proto" src={oghifi9}/>
                    <img className="card-img" id="proto" src={oghifi10}/>
                    <img className="card-img" id="proto" src={oghifi11}/>
                    <img className="card-img" id="proto" src={oghifi12}/>
                    <img className="card-img" id="proto" src={oghifi13}/>
                </div>
                <div className="cards-container">
                    <img className="card-img" id="proto" src={oghifi7}/>
                    <img className="card-img" id="proto" src={oghifi1}/>
                    <img className="card-img" id="proto" src={oghifi6}/>
                    <img className="card-img" id="proto" src={oghifi5}/>
                    <img className="card-img" id="proto" src={oghifi4}/>
                    <img className="card-img" id="proto" src={oghifi3}/>
                </div>

                <div class="page-subtitle" >Critique</div>

                <div class="body-text">Our initial interactive prototype was evaluated by our peers in a group critique. This gave us
                    a significant amount of feedback, which is summarized below.
                </div>
                <div class="body-text">
                    <ol>
                        <li><strong>Home Button + Navigation</strong>
                            <ul className="indented">
                                <li>The home button was difficult to find, and it's unclear what page it will link to
                                </li>
                                <li>A dropdown menu would be a more intuitive form of navigation</li>
                                <li>There aren't enough controls for navigation-- only option is to return to the home
                                    page
                                </li>
                            </ul>
                        </li>
                        <li><strong>Contrast</strong>
                            <ul className="indented">
                                <li>Thin white text could be inaccessible</li>
                                <li>Calendar is difficult to read</li>
                                <li>Colorful background makes it difficult to read text, particularly due to low opacity
                                    of text boxes
                                </li>
                            </ul>
                        </li>
                        <li><strong>Miscellaneous</strong>
                            <ul className="indented">
                                <li>Minimize button always leads to friends page</li>
                                <li>The calendar on the friend page is small and thus difficult to utilize</li>
                                <li>White/blank spaces in background selection page are confusing (note that this
                                    critique indicated that people were fundamentally misunderstanding the purpose of
                                    this page- thus, this is an issue of clearly demonstrating the goals of a page)
                                </li>
                                <li>Concept of pomodoro timer was unclear</li>
                            </ul>
                        </li>
                    </ol>
                </div>

            </div>
            <div className="segment-pink">
                <div className="segment-title">
                    Final High Fidelity Prototype
                </div>
                <div class="body-text">After group critique, we fundamentally changed a number of our prototype's features.
                </div>

                <div class="body-text">
                    <ol>
                        <li><strong>Home Button + Navigation</strong>
                            <ul className="indented">
                                <li>We replaced the home button with a larger and more obvious "back to scene" button,
                                    that takes users back to their selected video.
                                </li>
                                <li>Our landing page seemed to only distract users from the primary functionality of the
                                    site,
                                    which is audio and video selection. We fixed this by removing the landing page, and
                                    having the log in page link directly to the audio/video selection page.
                                </li>
                                <li>We implemented a new dropdown menu which drastically simplifies navigation and
                                    learnability.
                                </li>
                            </ul>
                        </li>
                        <li><strong>Contrast</strong>
                            <ul className="indented">
                                <li>We used darker backgrounds and a heavier font weight to address contrast issues.
                                </li>
                                <li>We increased the opacity of elements of the calendar, making it significantly more
                                    readable.
                                </li>
                                <li>We designated specific pages to be "scene" pages to solve the contrast issues
                                    created by a variable, colorful background
                                </li>
                            </ul>
                        </li>
                        <li><strong>Miscellaneous</strong>
                            <ul className="indented">
                                <li>We prototyped minimization options in all scenarios to reduce user confusion</li>
                                <li>We removed the calendar feature from the minimized version of the "break with
                                    friends" page, as it was too difficult to interact with.
                                </li>
                                <li>By completely redesigning our audio/video selection pages, we eliminated the
                                    confusion regarding white/blank space and made it clear that the "background" is in
                                    fact a key feature.
                                </li>
                                <li>We concluded that the point of the app was to allow users to relax. A focus timer
                                    made no sense in this context, so we chose to remove it.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>

                <div class="body-text">
                     <a href="https://www.figma.com/proto/k61Sy11rurvyZB575Rj9Rv/Lo-Fi-Iterative-Design-(Copy)?node-id=53%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=53%3A2&hotspot-hints=0&hide-ui=1" target="_blank"> Explore our prototype and features here</a>!
                </div>


                <div class="page-subtitle" >Interaction Principles</div>

                <div className="body-text">
                    <div className="cards-container">
                        <img className="card-img" src={example1}/>
                        <img className="card-img" src={example2}/>
                        <img className="card-img" src={example3}/>
                    </div>
                    <ol>
                        <li><strong>Contrast + Accessibility</strong>
                            <ul className="indented">
                                <li>We seriously addressed concerns regarding contrast and our current prototype has no
                                    contrast errors and is very accessible
                                </li>
                            </ul>
                        </li>
                        <li><strong>Visual Hierarchy</strong>
                            <ul className="indented">
                                <li>The hamburger menu is positioned in a common location and makes navigation intuitive
                                    and concise
                                </li>
                                <li>Information on all pages is linearly represented</li>
                            </ul>
                        </li>
                        <li><strong>Interaction</strong>
                            <ul className="indented">
                                <li>Nearly everything can be interacted with to some degree and the prototype embodies a
                                    minimum valuable product of this application
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>



            </div>

            <div className="segment-purple">
                <div className="segment-title">
                    User Testing Experiment
                </div>

                <div class="body-text">After revising our prototype, we conducted a usability test through a remote user testing service to get additional feedback about our design.
                </div>

                <div className="page-subtitle">Scenario</div>

                <div className="body-text">
                    You are a full-time remote freelance writer, and are looking to create a more relaxing work
                        environment.
                        Our app, LifeAt, creates this environment for you by playing relaxing video and audio,
                        connecting you with your friends to schedule breaks
                    and offering guided meditations. </div>
                    <div className="body-text">
                        <ol className="indented">
                            <li>Imagine you are a remote employee using our prototype to schedule a break with your
                                friends before you start working again
                            </li>
                            <li><strong>Main task: </strong>Set-up a relaxing Autumn work background, make sure to look
                                through the different audio options we provide for your background video
                            </li>
                            <li><strong>Subtask 1: </strong>Without leaving the homepage, describe what you would select
                                first. If you would leave without clicking anywhere, please explain why.
                            </li>
                            <li><strong>Subtask 2: </strong>In your work session, schedule a break with a friend in your
                                free time between 12-12:45.
                            </li>
                            <li><strong>Subtask 3: </strong>Browse our selection of guided meditation, choose which
                                meditation you would like to start.
                            </li>
                            <li><strong>Subtask 4: </strong>In your own words, describe how you might benefit from using
                                this application.
                            </li>
                        </ol>
                </div>
                <div className="page-subtitle">
                    Questionnaire
                </div>
                <div className="body-text">
                    <ol>
                        <li>What kinds of emotions do the aesthetics of the app evoke?</li>
                        <li>Did you find any actions or functionalities non-intuitive? If so, which ones and why?</li>
                        <li>When do you think you would use the minimized version versus the maximized version in your
                            work session?
                        </li>
                        <li>Between the audio and video features of our app, which would you use more often? Why?</li>
                    </ol>
                </div>
                <div className="page-subtitle">
                    Results
                </div>
                <div className="body-text">
                    If you would like to hear our users' feedback in their own words, please watch the user testing
                        videos for <a
                            href="https://app.usertesting.com/v/3517a6b9-def7-4650-9791-1eba730c892b?encrypted_video_handle=e1cbeb20-d0e6-45ba-ad3c-16f8473f8180#!/notes&shared_via=link"
                            target="_blank">user 1</a>,
                        <a href="https://app.usertesting.com/v/657d0687-9735-4d68-b040-a2e20421a038?encrypted_video_handle=3a4ee2cb-1775-41b2-abef-7656d028d53f#!/notes&shared_via=link"
                           target="_blank">user 2</a>, and
                        <a href="https://app.usertesting.com/v/011cb873-2257-483b-b505-8b95beaea8c0?encrypted_video_handle=82e12c1a-d189-42eb-aa60-c77f413510cd#!/notes&shared_via=link"
                           target="_blank">user 3</a>.

                </div>
                <div className="page-subtitle">
                    Analysis
                </div>

                <div className="page-subtitle">
                    User 1
                </div>
                <div className="body-text">
                    This reviewer pointed out that we may have over shot it when we were attempting to solve our contrast issues. She pointed out issues with how "black" our application appears
                    and in hindsight this critique absolutely has some merit. Instead of reducing all of the color in the background entirely we could likely have found a way to dilute it
                    and still keep the app vibrant while maintaining a reasonable level of contrast.Additionally, she noted that originally she wanted to try and schedule a meeting with friends in her profile. This points to the idea that our hamburger menu may not have
                    been as intuitive as it could have been, and the welcome Bill! message in the top right may just decrease learnability more than anything else. Finally, it would have been beneficial to add functionality that allowed users to simply click on the image instead of the maximize icon in the top right. This proved to be
                    unintuitive and confusing.

                </div>

                <div className="page-subtitle">
                    User 2
                </div>
                <div className="body-text">
                    This reviewer gave overall very positive feedback but was initially confused by our calendar functionality. Specifically, we offer a
                    calendar that shows when your friends may be available to take a break throughout the workday. She initially thought that the
                    availablity slots were already-scheduled breaks, rather than blocks where your friends may be
                    available. She eventually figured out the actual functionality, but in the future we
                    would like to make this less confusing. Additionally, this reviewer had a slight misinterpretation of the background scene.
                    She interpreted the background scene as social -- that users pick a background to
                    watch with friends, rather than play as background video/audio for work. We did not
                    intend this, though this could be a useful feature to add.
                </div>

                <div className="page-subtitle">
                    User 3
                </div>
                <div className="body-text">
                    This reviewer gave very positive feedback.
                    They noted that the site was clear and easy to use, and that it would allow
                    them to relax while working. One thing that we noticed is that although the reviewer noted that they found it easy to complete the second subtask (schedule a break with a friend), it seemed to take them a while to discover that they needed to use the dropdown
                    menu to find the "break with friends" page. We could have made this easier by revising our
                    navigation options to make page-to-page navigation a little more obvious.
                </div>

            </div>
        </personasbody>
    )
}
export default iterative;