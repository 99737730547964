import React from 'react';
import { Link } from 'react-router';
import NavigationVBar from "../Components/NavigationVBar";

function development() {
    return (
        <personasbody>
            <NavigationVBar/>
            <div> </div>
            <div class="segment-blue">
                <div class="page-title">
                    Library
                </div>
                <div className="page-subtitle">
                    This is a mock library that contains books across different genres and languages.
                    Many online libraries are catered towards limited languages: this project was an experiment
                    to find effective ways to display books of different languages in the same online library.
                </div>
            </div>

            <div className="segment-pink">
                <div className="segment-title">
                    Effective Traversal and Design:
                </div>
                <div className="body-text">
                    To accommodate both a large audience and diverse genres and languages of books,
                    I implemented a couple of sorting and filtering features that support user experience
                    when navigating an all-language library.
                </div>
                <div className="body-text"> {'\n'}</div>
                <div className="body-text">
                    A user can filter the displayed books according to language, genre, or a combination of both. Additionally, the user can sort these prices according to their good reads rating and their prices on amazon.
                </div>
                <div className="body-text"> {'\n'}</div>
                <div className="body-text">
                    Finally, the user can create a reading list by adding books they find interesting as they traverse through the selection.
                </div>
                <div className="body-text"> {'\n'}</div>
            </div>

            <div className="segment-yellow">
                <div className="segment-title">
                    Final Product
                </div>

                <div className="body-text">
                    You can find the deployed product in this <a
                    href="https://nervousturtle333.github.io/develop/" target="_blank">link over here</a>  and embedded below.

                </div>

                <iframe src="https://nervousturtle333.github.io/develop/"  width={1000} height={500} >
                </iframe>
            </div>

            <div className="segment-purple">
                <div className="segment-title">
                    Learnings and Findings
                </div>

                <div class="body-text">
                    On a technical level, this product allowed me to explore the foundation of React and some built-in libraries.

                </div>
                <div className="body-text">
                    On an application and conceptual level, I am thinking about novel and more effective ideas of filtering when it comes to both genres and languages. This selection of books was limited in scope in both areas and It pushed me to consider how can we accommodate 100+ languages and genres in one database.

                </div>
            </div>
        </personasbody>
    )

}
export default development;