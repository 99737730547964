import React from 'react';
import NavigationVBar from "../Components/NavigationVBar";
import dtopAnn from "./responsive/Desktop - Annotated.png";
import dtopNAnn from "./responsive/Desktop- Not Annotated.png";
import tabletAnn from "./responsive/iPad Pro - Annotated.png";
import tabletNAnn from "./responsive/iPad Pro - Not Annotated.png";
import phoneAnn from "./responsive/iPhone 14 - Annotated.png";
import phoneNAnn from "./responsive/iPhone 14 - Not Annotated.png";
import lofidtop from "./responsive/LofiDesktop.png";
import lofitablet from "./responsive/LofiTablet.png";
import lofiphone from "./responsive/LofiPhone.png";
import orginal from "./responsive/Original Website.png";
import styleguide from "./responsive/UI Style Guide.png";
import faucet from "./personas_project/faucet.png";

function responsive() {
    return(
        <personasbody>
            <NavigationVBar />
            <div></div>
            <div class="segment-blue">
                <div className="page-title">
                    Palestine Remembered</div>
                <div className="page-subtitle">
                    For this project, I chose the homepage of <a href="https://www.palestineremembered.com/index.html" target="_blank">Palestine Remembered</a> to redesign and restructure.
                    I chose this website because it is an archive of Palestinian history and villages that were raided and
                    ethnically cleansed. It serves as a resource of compiled documents, stories, and narratives that are
                    often unheard of and on the verge of erasure.
                </div>
            </div>

            <div className="segment-pink">
                <div className="segment-title">
                    Identifying Challenges in Original Website </div>

                <img src={orginal} className="img-fluid" alt="originalweb" width="70%" className="center"/>
                <div className="body-text"> {'\n'} </div>
                <div className="body-text">
                    Upon inspection of the Palestine Remembered website, I identify the following challenges:
                </div>

                <div className="page-subtitle">
                    Usability
                </div>


                    <div className="body-text">
                        This webpage overwhelms users with the number of buttons and options available.
                        Having both the vertical and horizontal nav bars results in inefficient
                        user directions. Additionally, multiple buttons and links are redundant and are not
                        grouped efficiently. It is also unclear how to switch between languages.
                    </div>

                    <div className="body-text">
                        When resized, the vertical navigation bar becomes a hamburger which is more effective.
                    </div>


                        <div className="page-subtitle">
                            Learnability
                        </div>

                    <div className="body-text">
                        Due to having many different options to select from and the frequency of redundant buttons and
                        links,
                        the website becomes more difficult to learn as multiple paths take to similar links and pages.
                        However,
                        navigating the back button with the two navigation bars is difficult and confusing.
                    </div>


                        <div className="page-subtitle">
                            Memorability
                        </div>

                    <div className="body-text">
                        Most pages the main page links to are similar in structure and elements. Additionally, the
                        majority of
                        the text and boxes do not indicate possible interactive elements. Therefore, the website loses
                        memorability
                        because of repetition and confusion. The two navigation bars are also easy to confuse. The
                        layout for all
                        page sections is identical and not visually seperated.
                    </div>


                        <div className="page-subtitle">
                            WAVE Accessibility Findings
                        </div>

                    <div className="body-text">
                        <li> 70 Missing alternative texts</li>
                        <li> 43 Very low contrast</li>
                        <li> 3 Unordered Lists</li>
                        <li> 40 Layout tables</li>
                    </div>

                <div className="body-text">
                    Palestine Remembered mainly struggles with layouts and displays on the screen, as indicated by the
                    many
                    table layouts found. As evident by the presence of unordered lists, the website also seems to need a
                    better
                    hierarchy and organization. Additionally, The contrast between the colors is incoherent and low.
                    Lastly,
                    there is a large dependency on icons and list items, however, most of them do not have appropriate
                    alternative
                    text.
                </div>


            </div>

            <div className="segment-purple">
                <div class="segment-title"> Visual Redesign: Low-fidelity Wireframing </div>
                <div class="body-text"> Below are my low-fidelity wireframes </div>
                <img src={lofidtop} className="img-fluid" alt="originalweb" width="100%" className="center"/>
                <div className={'body-text'}> {'\n'} </div>
                <div class="row">
                    <div>
                        <img src={lofitablet} className="img-fluid" alt="originalweb" width="70%" className="center"/>
                    </div>
                    <div>
                        <img src={lofiphone} className="img-fluid" alt="originalweb" width="65%" className="center"/>
                    </div>
                </div>
            </div>

            <div className="segment-yellow">
                <div className={"segment-title"}>
                    Visual Redesign: Visual Design Style Guide
                </div>
                <div class="body-text">
                    This is the style guide I followed when implementing both my hifi and html redesign.
                </div>

                <div className="body-text">
                    The color shades are inspired by the watermelon icon in <a href="https://www.nicepng.com/ourpic/u2q8q8a9w7w7i1i1_watermelon-vector-png-vector-transparent-download-sandia-png/" target="_blank"> this website. </a>
                </div>

                <img src={styleguide} className="img-fluid" alt="styleguide" width="100%" className="center"/>

            </div>

            <div class="segment-blue">
                <div className="segment-title"> Visual Redesign: High-fidelity Wireframing </div>
                <div className="body-text">
                    I include two versions of the hifi frames, some annotated with the grids used to code and others
                    without
                    the grid for the full visual experience.
                </div>
                <div class="row">
                    <div>
                        <img src={dtopAnn} className="img-fluid" alt="styleguide" width="83%" className="center"/>
                    </div>
                    <div>
                        <img src={dtopNAnn} className="img-fluid" alt="styleguide" width="100%" className="center"/>
                    </div>
                </div>

                <div class="body-text"> {'\n'} </div>

                <div className="row">
                    <div>
                        <img src={tabletAnn} className="img-fluid" alt="styleguide" width="82%" className="center"/>
                    </div>
                    <div>
                        <img src={tabletNAnn} className="img-fluid" alt="styleguide" width="100%" className="center"/>
                    </div>
                </div>

                <div className="body-text"> {'\n'} </div>

                <div className="row">
                    <div>
                        <img src={phoneAnn} className="img-fluid" alt="styleguide" width="81.5%" className="center"/>
                    </div>
                    <div>
                        <img src={phoneNAnn} className="img-fluid" alt="styleguide" width="100%" className="center"/>
                    </div>
                </div>

            </div>

            <div className="segment-pink">

                <div className="segment-title"> Responsive Redesign Product </div>

                <div className="body-text">
                    You can find my final product embedded below, and it is also <a
                    href="https://nervousturtle333.github.io/responsive-redesign/" target="_blank"> linked here</a>

                    The resigned website is responsive to Desktop, tablets, and phones.
                </div>

                <iframe src="https://nervousturtle333.github.io/responsive-redesign/"  width={1000} height={500} >
                </iframe>

            </div>

            <div className="segment-purple">
                <div className="segment-title"> Lessons Learned </div>

                <div className="body-text">
                    Throughout this project, I experimented with grids and flex boxes to handle page layout.
                    I also explored intentional programming and design for different-sized screens.
                </div>

                <div className="body-text">
                    Most importantly, however, I learned the importance of value-based work that revolves around giving
                    back
                    and protecting indigenous communities whose existence is constantly denied and fought.
                </div>

            </div>

        </personasbody>
    )

}
export default responsive;