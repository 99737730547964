import React from 'react';
import NavigationVBar from "../Components/NavigationVBar";
import "./personas_project/stylePersonas.css";
import faucet from "./personas_project/faucet.png";
import personaOne from "./personas_project/Paths-01.png";
import personaTwo from "./personas_project/Paths-02.png";
import storyboard from "./personas_project/Paths-03.png";

function personas() {
    return(
        <personasbody>
            <NavigationVBar />
            <div> </div>
            <div className="segment-blue">
                <div class = "page-title">
                    Studying Water Fountains
                </div>
                <div class = "page-subtitle">
                    For this project, I was asked to select a public interface and assess the ease
                    at which a user would interact with it. I decided to use a water fountain that is widely available
                    on campus.
                </div>
            </div>
            <div className="segment-pink">
                <div class = "segment-title" >
                    Interface Description
                </div>
                <img src={faucet} class="img-fluid" alt="faucetimage" width= "70%" className="center"/>
                <p className="body-text"> In order to use this interface, the user has to press the button situated
                    below the fountain itself. Upon pressing the button, there will be a stream of water exiting
                    through the water faucet in the corner. The user would tilt closer to the water stream in order
                    to drink, and the excess water would go down to the concave fountain structure and into the drain.
                </p>
            </div>
            <div className="segment-purple">
                <div class = "segment-title">
                    Observations
                </div>

                <div class = "body-text">
                    In order to gain a better perspective and understanding of how the interface presents itself,
                    I interviewed three users and recorded their interactions with the water fount
                </div>
                <div className="body-text">
                    One user was in a rush, they juggled quite a lot of materials in order to press the button.
                    They struggled to drink enough water as they were quite tall and had to stay in an uncomfortable
                    position. They seemed dissatisfied with the process.  This user also felt familiar with the product
                    and knew to press the button to get the water to flow. Their attitude towards the water fountain
                    indicated familiarity instead of product clarity.

                </div>
                <div className="body-text">
                    Another user expressed an expectation for a sensor and had hoped they would have found one instead
                    of the button. They also were shorter in height and struggled with reaching the fountain, as opposed
                    to the first user. Lastly, their immediate approach was to fill their water bottle using this water
                    fountain. They would tilt the bottle uncomfortably until water started pouring out.

                </div>
                <div className="body-text">
                    The last user was uncomfortable by how close they had to be to the faucet in order to drink water.
                    They tried their best to stay as far away as possible from the faucet. I realized later that they
                    found drinking from the faucet to be unsafe considering Covid Measures and the rising need for
                    caution.  They have seen users in the past get very close to the water faucet.
                </div>

                <div class="row">
                    <div>
                        <div className="page-subtitle">
                            Questions Asked
                        </div>
                        <div className="body-text">
                            <ul>
                                <li> Describe how the product presents itself to be used.</li>
                                <li> Do your expectations of how to use this product match how it is delivered?</li>
                                <li> Are there any unexpected observations that you did not predict?</li>
                                <li> What elements of the experience would you have improved or edited?</li>
                            </ul>
                        </div>
                    </div>

                    <div>

                        <div className="page-subtitle">
                            Recorded Answers
                        </div>
                        <div className="body-text">
                            <ul>
                                <li>The model of this water fountain makes it difficult to accommodate different physical heights </li>
                                <li>Not all users are comfortable with drinking directly from faucets and explored different
                                    alternatives to using the fountain. </li>
                                <li>All users expressed a desire in having higher water pressure</li>
                                <li>All users expressed a desire for having colder water offered </li>
                                <li>Most users felt like the product matched their expectations, however, they had hoped it
                                    would exceed them. </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className="segment-yellow">
                <div className="segment-title">
                    Personas: Who Uses Faucets?
                </div>

                <div className="body-text">
                    Based on my interviews with the three users, I created two user personas' empathy maps to better
                    contextualize the user experience and better understand user needs. You can find the personas below.
                </div>

                <img src={personaOne} className="img-fluid" alt="faucetimage" width="70%" className="center"/>
                <img src={personaTwo} className="img-fluid" alt="faucetimage" width="70%" className="center"/>

            </div>

            <div className="segment-blue">
                <div className="segment-title"> Storyboard</div>
                <div className="body-text">
                    Lastly, I created a storyboard to follow the journey of one of the users using the selected
                    interface. This storyboard follows the story of Mike, whose empathy map is shown in figure two of
                    the Personas section
                    <img src={storyboard} className="img-fluid" alt="faucetimage" width="70%" className="center"/>
                </div>
            </div>
        </personasbody>
    )

}
export default personas;